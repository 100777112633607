import React from 'react'

const TextBlock = ({ content, initials }) =>
  <section className='mb2 x xw fsC article-text-block c12'>
    <div className='article-texxxt x xw mxa c12'>
      <div>{initials.text}</div>
      <div dangerouslySetInnerHTML={{ __html: content.html }} />
    </div>
  </section>

export default TextBlock
