import React from 'react'
import { Link } from 'gatsby'

import PostTitle from './PostTitle'
import Img from './Image'

const HomepagePost = ({ node, index }) => {
  let layoutType
  switch (index) {
    case 0:
    case 1:
    case 8:
    case 9:
      layoutType = 'two-up'
      break
    case 2:
    case 3:
    case 7:
      layoutType = 'one-up'
      break
    case 4:
    case 5:
    case 6:
      layoutType = 'three-up'
      break
    default:
      layoutType = 'two-up'
  }

  return (
    <Link className={`x xw bcb hp-article-link hp-article-${layoutType}`} to={`/posts/${node.uid}`}>
      <div sm='dib' className='x xw c12 py1 bcb hp-article-parent'>
        <div className='hp-article-title'>
          <PostTitle
            title={node.data.title}
            field={node.data.field}
            company={node.data.company}
          />
        </div>
        <div className='hp-article-image c12'>
          <Img data={node.data.cover_photo.localFile.childImageSharp} />
        </div>
      </div>
    </Link>
  )
}

export default HomepagePost
