import React from 'react'

import { SettingsContext } from '../../settings-context'

const Option = ({ value }) => {
  return (
    <SettingsContext.Consumer>
      {(context) => {
        const selectThisSetting = () => {
          context.changeSetting('textSize', value)
        }

        return (
          <div
            onClick={selectThisSetting}
            className={` fsD post-option bcb ${context.textSize == value && 'selected bgc-b c-w'}`}
          >
            <span>{value}</span>
          </div>
        )
      }}
    </SettingsContext.Consumer>
  )
}

const TextSize = () => {
  return (
    <section className='c12 mb1'>
      <div className='fsD lh1-5 ttu'>Text Size</div>
      <div className='x xw c12 bcb b1 tac curp fsD'>
        <Option value='1' />
        <Option value='2' />
        <Option value='3' />
        <Option value='4' />
      </div>
    </section>
  )
}

export default TextSize
