import React, { PureComponent } from 'react'
import Swiper from 'react-id-swiper'

import Img from '../Image'

import '../../css/swiper.css'


const NextArrow = () =>
  <svg version='1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 81.7 62.1'>
    <path d='M50.7 0l-3.5 3.5 25 25H0v5h72.2l-25 25 3.5 3.5 31-31-31-31zm23.8 30.9l.1.1-.1.1v-.2z' />
  </svg>

const PrevArrow = () =>
  <svg style={{ transform: `rotate(180deg)` }} version='1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 81.7 62.1'>
    <path d='M50.7 0l-3.5 3.5 25 25H0v5h72.2l-25 25 3.5 3.5 31-31-31-31zm23.8 30.9l.1.1-.1.1v-.2z' />
  </svg>


const Image = ({ data }) => {
  return (
    <div className='swiper-slide'>
      <Img critical data={data} />
    </div>
  )
}

class PostImages extends PureComponent {
  constructor () {
    super()
    this.state = {
      activeIndex: 1
    }

    this.swiper = null
    this.goNext = this.goNext.bind(this)
    this.goPrev = this.goPrev.bind(this)
  }

  goNext () {
    if (this.swiper) {
      this.swiper.slideNext()
      this.setState({ activeIndex: this.swiper.realIndex + 1 })
    }
  }

  goPrev () {
    if (this.swiper) {
      this.swiper.slidePrev()
      this.setState({ activeIndex: this.swiper.realIndex + 1 })
    }
  }

  render () {
    const { images } = this.props
    const params = {
      slidesPerView: 1,
      loop: true,
      speed: 0,
      autoHeight: true,
      allowTouchMove: false
    }
    return (
      <section sm='pl0 c12' className='c6  x psr'>
        <div onClick={this.goNext} className='article-images oh curp'>
          <Swiper {...params} ref={node => { if (node) this.swiper = node.swiper }}>
            {images.map((image, i) => <Image key={`img-${i}`} data={image.featured_image.localFile.childImageSharp} url={image.featured_image.url} />)}
          </Swiper>
        </div>
        <div className='article-header-controls h100 psr'>
          <div className='tar c12'>
            <div className='curp c12 tar arrow' onClick={this.goNext}>
              <NextArrow />
            </div>
            <div className='curp c12 tar arrow' onClick={this.goPrev}>
              <PrevArrow />
            </div>
          </div>

          <div className='psa b0 r0'>
            {this.state.activeIndex}/{this.props.images.length}
          </div>
        </div>
      </section>
    )
  }
}

export default PostImages
