import React from 'react'
import { Helmet } from 'react-helmet'

import PostText from './PostText'
import PostImages from './PostImages'

const PostHeader = ({
  company,
  title,
  field,
  links,
  description,
  featured_images }) => {
  let postTextWidth = featured_images.length > 1 ? 'c6' : 'c12'
  return (
    <header className='x xw mxa line-mw x xw py1 bb1 bcb psr fsC'>
      <Helmet>
        <title>{`${title.text}, ${field.text} ${company.text ? company.text + ' ' : ''}- On Practices`}</title>
        <meta name='description' content={description.text} />
      </Helmet>
      <PostText
        postTextWidth={postTextWidth}
        field={field}
        company={company}
        title={title}
        description={description}
        links={links}
      />
      {featured_images.length > 1 && <PostImages images={featured_images} />}
    </header>
  )
}

export default PostHeader
