import React from 'react'

import PostTitle from '../PostTitle'

const Link = ({ link, link_title }) => {
  if (link) {
    return <a href={link.url} className='fsC db tar' target='_blank' rel='noopener noreferrer'>{link_title.text}</a>
  } else {
    return false
  }
}

const PostText = ({
  company,
  title,
  links,
  field,
  postTextWidth,
  description }) =>
    <div id='post-text' sm='c12' className={`${postTextWidth} psr br1 bcb`}>
      <div sm='pr0 mb1' className='x xw xjb pr1 mb2'>
        <PostTitle field={field} company={company} title={title} />
        <div>
          {links.map((link, i) => <Link {...link} key={`link-${i}`} />)}
        </div>
      </div>
      <div sm='psr c12 mb2' className='c8 psa b0 l0'>
        <div dangerouslySetInnerHTML={{ __html: description.html }} />
      </div>
    </div>

export default PostText
