import React from 'react'

import { SettingsContext } from '../../settings-context'

let mouseDown = false

const WidthOption = ({ option, i }) => {
  return (
    <span value={i + 1} className='width-option'>
      {option}
    </span>
  )
}

const WidthOptions = Array(10).fill()

const getElementFromTouchMove = e => {
  const target = document.elementFromPoint(e.changedTouches[0].clientX, e.changedTouches[0].clientY)
  return target
}

const WidthSize = () => {
  return (
    <section className='c12 mb1'>
      <div className='fsD lh1-5 ttu'>Width</div>
      <SettingsContext.Consumer>
        {(context) => {
          const handleMouseDown = () => {
            mouseDown = true
          }
          const handleMouseUp = () => {
            mouseDown = false
          }
          const handleMouseMove = e => {
            if (mouseDown) {
              if (e.type === 'touchmove') {
                submitValue(getElementFromTouchMove(e))
              } else {
                submitValue(e.target)
              }
            }
          }

          const handleClick = e => {
            submitValue(e.target)
          }

          const submitValue = (target) => {
            if (!target.hasAttribute('value')) return false

            const value = parseInt(target.getAttribute('value'))
            context.changeSetting('width', value)
            // console.log(e, ' submit value')
            // const processedWidth = Math.floor(e.clientX / (e.target.offsetWidth + 20) * 10)
            // context.changeSetting('width', processedWidth)
          }

          return (
            <div id='select-width'
              onTouchStart={handleMouseDown}
              onTouchEnd={handleMouseUp}
              onTouchMove={handleMouseMove}
              onMouseDown={handleMouseDown}
              onMouseUp={handleMouseUp}
              onMouseMove={handleMouseMove}
              onClick={handleClick}
              onMouseLeave={handleMouseUp} className='x xw c12 bcb b1 tac curp psr'>
              <span style={{ width: `${context.width * 10}%` }} className='psa t0 l0 h100 bgc-b select-width-slider' />
              {WidthOptions.map((option, i) => <WidthOption i={i} key={i} option={option} />)}
            </div>
          )
        }}
      </SettingsContext.Consumer>
    </section>
  )
}

export default WidthSize
