import React, { PureComponent } from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import PostHeader from '../components/Post/PostHeader'
import PostFooter from '../components/Post/PostFooter'
import PostSettings from '../components/PostSettings/'
import SelectedImage from '../components/Post/SelectedImage'
import Signature from '../components/Signature'

import TextBlock from '../components/Post/TextBlock'
import ImageBlock from '../components/Post/ImageBlock'
import CaptionBlock from '../components/Post/CaptionBlock'

class Post extends PureComponent {
  constructor () {
    super()

    this.state = {
      selectedImage: false,
      scrollAmount: 0,
      scrollOffset: 10,
      showingCredits: true
    }

    this.openFeaturedImage = this.openFeaturedImage.bind(this)
    this.closeFeaturedImage = this.closeFeaturedImage.bind(this)

    this.scrollListener = this.scrollListener.bind(this)
  }

  componentDidMount () {
    window.addEventListener('scroll', this.scrollListener)
  }

  componentWillUnmount () {
    window.removeEventListener('scroll', this.scrollListener)
  }

  scrollListener () {
    if (this.state.selectedImage) {
      this.setState({ scrollAmount: this.state.scrollAmount + 1 })
      if (this.state.scrollAmount >= this.state.scrollOffset) {
        this.setState({ selectedImage: false })
      }
    }
  }

  openFeaturedImage (data) {
    this.setState({ selectedImage: data },
      this.setState({ scrollAmount: 0 }
    ))
  }

  closeFeaturedImage () {
    this.setState({ selectedImage: false })
  }

  render () {
    const { selectedImage } = this.state
    const { data } = this.props
    const postContent = data.post

    let content = false

    if (postContent.data.body) {
      content = postContent.data.body.map((content, i) => {
        if (content.__typename === 'PrismicPostBodyText') {
          return <TextBlock key={`s-${i}`} {...content.primary} />
        } else if (content.__typename === 'PrismicPostBodyImage') {
          return <ImageBlock openFeaturedImage={this.openFeaturedImage} key={`s-${i}`} {...content.primary} />
        } else if (content.__typename === 'PrismicPostBodyCaption') {
          return <CaptionBlock {...content.primary} key={`s-${i}`} {...content.primary} />
        } else return false
      })
    }

    return (
      <Layout>
        {selectedImage && <SelectedImage closeFeaturedImage={this.closeFeaturedImage} data={selectedImage} /> }
        <PostHeader {...postContent.data} />
        <div sm='py2' className='mxa py4 px1 x xw psr content'>
          <PostSettings />
          <div id='post-content' className='c12'>{content}</div>
          <Signature />
        </div>
        <PostFooter currentPost={data.post.uid} allPosts={data.allPosts.edges} />
      </Layout>
    )
  }
}

export default Post

// this is how you alias and have multiple queries in graphql
// allPosts: allPrismicPost
// post: ..../
export const pageQuery = graphql`
  query PostBySlug($uid: String!) {
    post: prismicPost(uid: { eq: $uid }) {
      uid
      id
      data {
        title {
          html
          text
        }
        field {
          html
          text
        }
        company {
          html
          text
        }
        cover_photo {
          alt
          copyright
          url
          localFile{
            childImageSharp {
              fluid(quality: 95) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        description {
          html
          text
        }
        links {
          link_title {
            html
            text
          }
          link {
            link_type
            url
            target
          }
        }
        featured_images {
          featured_image {
            alt
            copyright
            url
            localFile{
              childImageSharp {
                fluid(quality: 95) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
        body {
          ... on PrismicPostBodyText {
            primary {
              content {
                html
                text
              }
              initials {
                html
                text
              }
            }
          }
          ... on PrismicPostBodyCaption {
            primary {
              content {
                html
                text
              }
            }
          }
          ... on PrismicPostBodyImage {
            primary {
              image {
                alt
                copyright
                url
                localFile{
                  childImageSharp {
                    fluid(quality: 95) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
              image_caption {
                html
                text
              }
            }
          }
        }
      }
    }
    allPosts: allPrismicPost {
      edges {
        node {
          id
          uid
          data {
            title {
              html
              text
            }
            field {
              html
              text
            }
            company {
              html
              text
            }
            cover_photo {
              url
              localFile{
                childImageSharp {
                  fluid(quality: 95) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
