import React, { PureComponent } from 'react'
import { Helmet } from 'react-helmet'
import { withPrefix } from 'gatsby'

import { SettingsContext } from '../settings-context'

import Header from './Header/'
import Footer from './Footer'

import '../css/reset.css'
import '../css/util.css'
import '../css/fonts.css'
import '../css/main.css'

class Layout extends PureComponent {
  constructor () {
    super()
    this.state = {
      settingsActive: false,
      informationActive: false,
      font: 'sans',
      color: 'light',
      textSize: '2',
      imageSize: 'M',
      width: 5,
      logos: false,
      creditsShowing: false,
      changeSetting: (type, value, dontPushToLocalStorage = false) => {
        this.setState({ [type]: value })
        if (!dontPushToLocalStorage) window.localStorage.setItem(type, value)
      }
    }

    this.hydrateStateWithLocalStorage = this.hydrateStateWithLocalStorage.bind(this)
  }

  componentDidMount () {
    this.setState({ width: window.innerWidth > 768 ? '5' : '8' }, () => {
      this.hydrateStateWithLocalStorage()
      if (window.location.search === '?submitted=true') {
        setTimeout(() => {
          window.scrollTo(0, 9999)
        }, 400)
      }
    })

    document.addEventListener('keyup', this.keyUpListener)
  }

  componentWillUnmount () {
    document.removeEventListener('keyup', this.keyUpListener)
  }

  hydrateStateWithLocalStorage () {
    for (let key in this.state) {
      if (window.localStorage.hasOwnProperty(key)) {
        let value = window.localStorage.getItem(key)
        if (key === 'logos') return

        try {
          value = JSON.parse(value)
          this.setState({ [key]: value })
        } catch (e) {
          console.log('------------ erorrrrrrr', e)
          this.setState({ [key]: value })
        }
      }
    }

    console.log(this.state, ' this state')
  }

  toggleActiveState = (setting, value) => {
    this.setState({ [setting]: value })
  }

  closeAllActiveStates = () => {
    this.setState({
      informationActive: false,
      settingsActive: false
    })
  }

  keyUpListener = e => {
    console.log(e, ' eee')
    if (e.key == 'Escape' || e.keyCode == '27') {
      this.closeAllActiveStates()
    }
  }

  render () {
    const { children } = this.props
    const { font, color, textSize, imageSize, width, logos, settingsActive, informationActive } = this.state

    const css = `bgc-g
    whole-font-${ font }
    whole-color-${ color }
    whole-textSize-${ textSize }
    whole-imageSize-${ imageSize }
    whole-width-${ width }
    whole-logos-${ logos }
    body_blurred-${ this.props.bodyBlurred }`

    if (typeof document !== 'undefined') document.body.className = css
    
    return (
      <>
        <Helmet>
          <meta charset='utf-8' />
          <meta name='description' content='On Practices is an online editorial platform for dialogue on building and maintaining creative practices. Each round is comprised of ten text-based interviews with notable practitioners in various spaces.' />
          
          <meta name='viewport' content='width=device-width, initial-scale=1' />
          <meta name='robots' content='index,follow' />
          <meta name='googlebot' content='index,follow' />
          <meta name='twitter:dnt' content='on' />

          <meta property='og:type' content='website' />
          <meta property='og:image' content={withPrefix('promo.png')} />
          <meta property='og:site_name' content='On Practices' />

          <meta name='twitter:creator' content='@56_digital' />
          <meta name='twitter:image' content={withPrefix('promo.png')} />
                          
          <link rel='icon' type='image/png' href={`${withPrefix('logo.png')}`} sizes='320x320' />
          
          
        </Helmet>
        <SettingsContext.Provider value={this.state}>
          <main className={css}>
            <div className='site-body os'>
              <Header toggleActiveState={this.toggleActiveState} settingsActive={settingsActive} informationActive={informationActive} />
              <article onClick={this.closeAllActiveStates}>
                {children}
                <Footer />
              </article>
            </div>
          </main>
        </SettingsContext.Provider>
      </>
    )
  }
}

Layout.defaultProps = {
  bodyBlurred: false
}

export default Layout
