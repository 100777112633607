import React from 'react'

import Img from '../Image'

const ImageBlock = ({ image, image_caption, openFeaturedImage }) => {
  const ImageElement = <Img data={image.localFile.childImageSharp} />
  const open = () => {
    openFeaturedImage(ImageElement)
  }

  return (
    <section onClick={open} className='mb4 mxa article-image-block fsC curzi'>
      <figure>
        {ImageElement}
        {/* <img src={image.url} /> */}
        {image_caption.text && <span className='db mt1'>{image_caption.text}</span>}
      </figure>
    </section>
  )
}

export default ImageBlock
