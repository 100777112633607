import React from 'react'

const SelectedImage = ({ data, closeFeaturedImage }) => {
  const close = () => {
    closeFeaturedImage()
  }
  return (
    <figure id='selected-image' onClick={close} className='psf t0 l0 c12 vh100 c12 z4 curzo x xw xac xjc px1 py1'>
      <div className='c12'>
        {data}
        {/* <Img data={data.localFile.childImageSharp} /> */}
      </div>
    </figure>
  )
}

export default SelectedImage
