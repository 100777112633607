import React from 'react'

import TextSize from './TextSize'
import ImageSize from './ImageSize'
import WidthSize from './WidthSize'

const Settings = () => {
  return (
    <aside sm='py0 px0 psr c12' className='mb3 z3 psa l0 t0 px1 py4' id='post-settings'>
      <TextSize />
      <ImageSize />
      <WidthSize />
    </aside>
  )
}

export default Settings
