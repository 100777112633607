import React from 'react'

import HomepagePost from '../HomepagePost'

const PostFooter = ({ allPosts, currentPost }) => {
  let prevPost
  let nextPost

  allPosts.forEach((post, i) => {
    if (post.node.uid === currentPost) {
      // setup Previous Post
      if (allPosts[i - 1]) prevPost = allPosts[i - 1].node
      else prevPost = allPosts[allPosts.length - 1].node

      // setup Next Post
      if (allPosts[i + 1]) nextPost = allPosts[i + 1].node
      else nextPost = allPosts[0].node
    }
  })

  return (
    <section className='line-mw mxa mcb bt1 bcb pb1 x xw'>
      <HomepagePost node={prevPost} />
      <HomepagePost node={nextPost} />
    </section>
  )
}

export default PostFooter
