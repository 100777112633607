import React, { PureComponent } from 'react'
import Img from 'gatsby-image'

class Image extends PureComponent {
  render () {
    const { data, critical = false } = this.props
    return (
      <Img critical={critical} fluid={data.fluid} />
    )
  }
}

export default Image
