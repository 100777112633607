import React, { PureComponent } from 'react'

import { SettingsContext } from '../settings-context'

import svgs from './Signoff'

const images = [0, 1, 2, 3, 4]

class Signature extends PureComponent {
  render () {
    const randomNumber = Math.floor(Math.random() * (images.length - 1)) + 1
    const Svg = svgs[randomNumber]
    return (
      <SettingsContext.Consumer>
        {context => {
          const openCredits = () => {
            context.changeSetting('logos', !context.logos)
          }

          return (
            <div onClick={openCredits} id='sig' sm='c12' className='mxa curp c7'>
              <Svg />
            </div>
          )
        }}
      </SettingsContext.Consumer>
    )
  }
}

export default Signature
