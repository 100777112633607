import React from 'react'

import { SettingsContext } from '../../settings-context'

const Option = ({ value }) => {
  return (
    <SettingsContext.Consumer>
      {(context) => {
        const selectThisSetting = () => {
          context.changeSetting('imageSize', value)
        }

        return (
          <div
            onClick={selectThisSetting}
            className={` fsD post-option bcb ${context.imageSize === value && 'selected bgc-b c-w'}`}
          >
            <span>{value}</span>
          </div>
        )
      }}
    </SettingsContext.Consumer>
  )
}

const ImageSize = () => {
  return (
    <section className='c12 mb1'>
      <div className='fsD lh1-5 ttu'>Image Size</div>
      <div className='x xw c12 bcb b1 tac curp fsD'>
        <Option value='S' />
        <Option value='M' />
        <Option value='L' />
      </div>
    </section>
  )
}

export default ImageSize
