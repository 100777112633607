import React from 'react'

const PostTitle = ({ title, field, company }) =>
  <h2 sm='' className='fsB pb1'>
    <span className='db'>{title.text}</span>
    {field.text && <span className='db'>{field.text}</span>}
    {company.text && <span className='db'>{company.text}</span>}
  </h2>

export default PostTitle
